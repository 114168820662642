
/** 
* el dom 
* top 目标位置
* ms 执行时间
*/
const scrollAnimation = (cfg) => {
  if (window.tmpAnimationHandle){
    window.cancelAnimationFrame(window.tmpAnimationHandle) 
  }
  
  if (!cfg.el){
    console.error('scrollAnimation need a el')
    return
  }

  if (isNaN(cfg.top)){
    console.error('scrollAnimation need a top')
    return
  }


  let st=cfg.el.scrollTop

  // 当前与目标相等代表重复点击，没意义，直接退出
  if (cfg.top===st){
    return
  }

  cfg.ms=cfg.ms || 2000

  let initTimestamp
  
  function innerFunc(timestamp){
    console.log(timestamp)
    
    if (!initTimestamp){
      initTimestamp=timestamp
    }

    let targetTop=(timestamp-initTimestamp)/cfg.ms*(cfg.top-st)+st

    
    cfg.el.scrollTo({
      top:targetTop
    })

    // 向上的
    console.log(targetTop,cfg.top)
    if (cfg.top<st &&  targetTop<=cfg.top){
      cfg.el.scrollTo({
        top:cfg.top
      })
      return
    }

    if (cfg.top>st &&  targetTop>=cfg.top){
      cfg.el.scrollTo({
        top:cfg.top
      })
      return
    }


    window.tmpAnimationHandle=requestAnimationFrame(innerFunc)

  }

  window.tmpAnimationHandle=requestAnimationFrame(innerFunc)
  
};

/**
 * 
 * 复制到剪贴板 cb 回调函数
*/

function toClipBoard(str,cb){
  if (!cb){
    cb=function(){}
  }

  // use clipboard obj
  if (navigator && navigator.clipboard){
    navigator.clipboard.writeText(str).then(cb)
    return true
  }

  // use selection

  if (window.getSelection && document.createRange){
    let inp=document.createElement('span')
    inp.setAttribute('display','none')
  
    let bd=document.body
    bd.appendChild(inp)
    inp.innerText=str
    let sel=window.getSelection()
    sel.removeAllRanges()
    let range=document.createRange()
    range.selectNode(inp)
    sel.addRange(range)
    let bol=document.execCommand('copy')
    bd.removeChild(inp)
    console.log(bol)
    // inp.remove()
    if (bol && cb){
      cb()
    }

    return true
  }else{
    let inp=document.createElement('input')
    inp.setAttribute('style','height:0px;opacity:0;')
  
    let bd=document.body
    bd.appendChild(inp)
    inp.setAttribute('value',str)
    inp.select()
    let bol=document.execCommand('copy')
    bd.removeChild(inp)
    console.log(bol)
    // inp.remove()
    if (bol && cb){
      cb()
    }
  }



}

function getRowLength(direction){
  let ww=document.documentElement.clientWidth
  let hh=document.documentElement.clientHeight

  if (direction==='v'){
    ww=375
  }else{
    ww=(ww/hh)*375
  }

  let wwRem=ww/16-2
  let movieWidth=6.625
  return Math.floor(wwRem/movieWidth)
}

function insertDom(label,targetDom,attrs,innerHTML){

  let dom=document.createElement(label)
  for (let key in attrs){
    dom.setAttribute(key,attrs[key])
  }
  
  targetDom.appendChild(dom)
  if (innerHTML){
    dom.innerHTML=innerHTML
  }

  return dom

}

export {scrollAnimation,toClipBoard,getRowLength,insertDom}