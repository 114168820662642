import localforage from "localforage"

let db=localforage.createInstance({
  name:'search'
})

let sortFunc=function(state){
  let toSort=state.records.map((item,index)=>{
    return {
      index,
      timeStamp:item.timeStamp
    }
  })
  toSort.sort((a,b)=>{
    return b.timeStamp-a.timeStamp
  })
  let res=toSort.map((item)=>{
    return state.records[item.index]
  })

  return res
}

export default {
  namespaced:true,
  state:{
    keyword:'',
    records:[]
  },
  getters:{
    sortRecords(state){
      return sortFunc(state)
    }
  },
  mutations:{
    SYNC_RECORDS(state,arr){
      state.records=arr
    },
    SET_KEYWORD(state,str){

      state.keyword=str
    }
  },
  actions:{
    setKeyword({commit,dispatch},str){
      commit('SET_KEYWORD',str)
      if (str!==''){
        dispatch('add',str)
      }
    },
    add({dispatch},text){

      db.setItem(text,{
        keyword:text,
        timeStamp:new Date().getTime()
      }).then(()=>{
        dispatch("sync")
      })
    },
    remove({dispatch},text){

      db.removeItem(text).then(()=>{
        dispatch("sync")
      })
    },
    sync({commit}){

      let arr=[]
      db.iterate((val)=>{
        arr.push(val)
      }).then(()=>{
        commit('SYNC_RECORDS',arr)
      })
    },
    clear({dispatch}){
      db.clear().then(()=>{
        dispatch("sync")
      })
    }
  }

}