/* 不能出现包含任何ad字样的代码，会被浏览器屏蔽，包括文件名 */

import request from '@/utils/request'

export function listFloatAfs(data){

  return request({
    url:'/app/ad/float/pageNotice',
    method:'post',
    data
  })
}

export function listAfs(data){
  return request({
    url:'/app/ad/space/pageNotice',
    method:'post',
    data
  })
}