import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import infiniteScroll from 'vue-infinite-scroll'
import router from '@/router/index'
import store from '@/store/index'

Vue.use(VueRouter)
Vue.use(infiniteScroll)

Vue.config.productionTip = false

router.beforeEach((to,from,next)=>{
  if (to.meta.title){
    document.title=to.meta.title
  }

  next()
})

new Vue({
  render: h => h(App),
  router,
  store

}).$mount('#app')
