<template>

  <div class="gg-wrapper-rb" v-show="show && afCfg">
    <span class="close" @click.stop="show=false" v-show="closeBtnShow">关闭</span>
    <a :href="afCfg.wapUrl || afCfg.pcUrl" target="_blank">
      <img :src="afCfg.webImg || afCfg.pcImg" alt="">
    </a>
  </div>
</template>
<script>
import {mapState } from 'vuex'

export default {
  props:{
    width:{
      type:String,
      default:'100%'
    },
    height:{
      type:String,
      default:'200px'
    },
    name:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      show:true,
      closeBtnShow:false
    }
  },
  computed:{
    afCfg(){
      let findObj=this.floatAfs.find((item)=>{
        return item.name===this.name
      })
      if (findObj){
        return findObj
      }else{
        return false
      }
    },
    ...mapState({
      floatAfs:state=>state.af.floatAfs,
      webUrl:state=>state.setting.webUrl
    })
  },
  mounted(){
    setTimeout(()=>{
      this.closeBtnShow=true
    },3000)
  }
}
</script>
<style scoped>
/* css不能出现ad字样 会被广告插件屏蔽 */

.gg-wrapper-rb{
  position: fixed;
  width:18.75rem;
  height:18.75rem;
  right:0;
  bottom:0;
  background-color: #D3D3D3;
  font-size: 2.5rem;
  color:#FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.gg-wrapper-rb .close{
  position: absolute;
  top:0;
  left: 0;
  color:#04CB94;
  font-size: 0.875rem;
  cursor: pointer;
  text-shadow:0 0.0625rem #FFF,0.0625rem 0 #FFF,-0.0625rem 0 #FFF,0 -0.0625rem #FFF;
  font-weight: bold;
}

.gg-wrapper-rb img{
  width:100%;
  height:100%;
  display: block;
}

</style>