import Vue from 'vue'
import Vuex from 'vuex'
import search from './modules/search'
import history from './modules/history'
import setting from './modules/setting'
import af from './modules/af'

Vue.use(Vuex)


const store = new Vuex.Store({
  modules: {
    search,
    history,
    setting,
    af
  }
  
})

export default store