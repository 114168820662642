<template>
  <div id="app">
    <router-view></router-view>


    <BaseAfFullscreen name="首页弹窗广告位" />
    <baseAfRb name="右下角悬浮广告位" />
  </div>
</template>

<script>

import BaseAfFullscreen from '@/components/base-af-fullscreen.vue'
import baseAfRb  from './components/base-af-rb'

export default {
  name: 'App',
  components:{
    BaseAfFullscreen,
    baseAfRb
  },
  methods:{
    initApp(){
      this.$store.dispatch('history/sync')
      this.$store.dispatch('search/sync')
      this.$store.dispatch('setting/sync')
      this.$store.dispatch('af/sync')
    }
  },
  mounted(){
    console.log('App mounted')
    this.initApp()
    // this.$message('测试测试')
  },
  beforeDestroy(){
    console.log('App destory')
  }
}
</script>
<style>
body,html{
  margin:0px;
  padding:0px;
  font-family: -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif;
  color:#303030;
}

*{
  -webkit-tap-highlight-color: transparent;
}

p{
  margin:0px;
  padding:0px;
}

#app{
  background-color:#F7F8FA ;
  width:100%;
  min-height:calc( 100vh );
}

.container-wrapper::-webkit-scrollbar{
  display: none;
}

.pointer{
  cursor: pointer;
}

.container-wrapper{
  width:100%;
  margin:0 auto;
  position: relative;
  padding-top:70px;
}



.hide{
  display: none !important;
}

.mt16{
  margin-top:1rem;
}

.mb16{
  margin-bottom:1rem;
}

.mb10{
  margin-bottom:0.625rem;
}

.mb12{
  margin-bottom:0.75rem;
}

.h15{
  height:0.9375rem;
}

.w100p{
  width:100%;
}

/* fade animate */
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.fade-enter-to,.fade-leave{
  opacity: 1;
}

/*  mint */
.mint-swipe,.mint-swipe-items-wrap{overflow:hidden;position:relative;height:100%}.mint-swipe-items-wrap{-webkit-transform:translateZ(0);transform:translateZ(0)}.mint-swipe-items-wrap>div{position:absolute;-webkit-transform:translateX(-100%);transform:translateX(-100%);width:100%;height:100%;display:none}.mint-swipe-items-wrap>div.is-active{display:block;-webkit-transform:none;transform:none}.mint-swipe-indicators{position:absolute;bottom:10px;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%)}.mint-swipe-indicator{width:8px;height:8px;display:inline-block;border-radius:100%;background:#000;opacity:.2;margin:0 3px}.mint-swipe-indicator.is-active{background:#fff}

</style>

