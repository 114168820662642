import {listFloatAfs,listAfs} from '@/api/af'

export default {
  namespaced:true,
  state:{
    floatAfs:[],
    afs:[],
  },
  mutations:{
    SET_FLOATADS(state,res){
      state.floatAfs=res


    },
    SET_AFS(state,res){
      state.afs=res
    }
  },
  actions:{
    sync({commit}){
      listFloatAfs({
        pageNum:1,
        pageSize:100
      }).then((res)=>{
        res=res.data
        commit('SET_FLOATADS',res.records)
      })
      listAfs({
        pageNum:1,
        pageSize:100
      }).then((res)=>{
        res=res.data
        commit('SET_AFS',res.records)
      })
    }
  }
}