<template>

  <div class="gg-wrapper-f" v-show="show && afCfg">
    <a :href="afCfg.wapUrl || afCfg.pcUrl" class="ico-wrapper" target="_blank">
      <img :src="afCfg.webImg || afCfg.pcImg" alt="" class="ico">
    </a>
    <img src="/icons/close-ico@2x.png" alt="" class="close" @click="show=false">
  </div>
</template>
<script>
import {mapState } from 'vuex'

export default {
  props:{
    width:{
      type:String,
      default:'100%'
    },
    height:{
      type:String,
      default:'200px'
    },
    name:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      show:true
    }
  },
  computed:{
    afCfg(){
      let findObj=this.floatAfs.find((item)=>{
        return item.name===this.name
      })
      if (findObj){
        return findObj
      }else{
        return false
      }
    },
    ...mapState({
      floatAfs:state=>state.af.floatAfs,
      webUrl:state=>state.setting.webUrl
    })
  }
}
</script>
<style scoped>
.gg-wrapper-f{
  position: fixed;
  width:100%;
  height:calc( 100vh );
  top:0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0,0,0,0.4);
  z-index:999999;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.gg-wrapper-f .ico-wrapper{
  display: block;
  width:18.75rem;
  height:28.375rem;
}

.gg-wrapper-f .ico-wrapper .ico{
  width:100%;
  height:100%;
  display: block;
}

.gg-wrapper-f .close{
  width:3.125rem;
  height:3.125rem;
  margin-top:1rem;
  cursor: pointer;
}

</style>