<template>
  <div class="toast-sub-wrapper">
    <img :src="imgBase64" alt="">
  </div>
</template>
<script>
import {detailSubscribe} from '@/api/subscribe'
export default {
  data(){
    return{
      imgBase64:'',
    }
  },
  methods:{
    getSubscribe(){
      detailSubscribe().then((res)=>{
        this.imgBase64=res.data.img
      })
    }
  },
  mounted(){
    this.getSubscribe()
  }
}
</script>
<style scoped>
.toast-sub-wrapper>img{
  display: block;
  width:100%;
}
</style>