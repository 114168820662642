<template>
<div class="contai-wrapper">
  <div class="container">
    <BaseChildrenHeader title="404"/>

    <img src="/icons/404@2x.png" alt="" class="logo mb24">
    <p class="text mb12">网络无法连接，请点击刷新</p>
    <img src="/images/qrcode2.jpg" alt="" class="qrcode mb24">

    <a href="javascript:;" class="download-btn  mb24">返回首页</a>

    <p class="text mb34">问题反馈请前往“青麦QIMAI”公众号</p>
    <div class="wx-wrapper">
      <BaseWx />
    </div>
  </div>
</div>

</template>
<script>
import BaseChildrenHeader from '@/components/base-children-header'
import BaseWx from '@/components/base-wx'

export default {
  components:{
    BaseChildrenHeader,
    BaseWx
  },
  data(){
    return{

    }
  }
}
</script>

<style scoped>
.contai-wrapper{
  width:100%;
  max-height:calc( 100vh );
  background-color: #FFF;
  overflow: auto;
}

.container{
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding:3.75rem 1rem 1rem;
  box-sizing: border-box;
}

.logo{
  width: 15rem;
  height: 11.25rem;
}

.mb24{
  margin-bottom:1.5rem;
}

.mb34{
  margin-bottom:2.125rem;
}

.mb12{
  margin-bottom:0.75rem;
}

.text{
  color:#303030;
  font-size: 0.875rem;
}

.fwb{
  font-weight: bold;
}

.download-btn{
  display: block;
  text-decoration: none;
  text-align: center;
  width: 6.125rem;
  height: 2.1875rem;
  line-height: 2.1875rem;
  border-radius: 1.125rem;
  background-color: #04cb94;
  font-size: 0.875rem;
  color:#FFF;
  margin-bottom: 1.625rem;
}


.wx-wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


</style>