import localforage from "localforage"

let db=localforage.createInstance({
  name:'history'
})

export default {
  namespaced:true,
  state:{
    records:[]
  },
  getters:{
    sortRecords(state){
      let toSort=state.records.map((item,index)=>{
        return {
          index,
          timeStamp:item.timeStamp
        }
      })
      toSort.sort((a,b)=>{
        return b.timeStamp-a.timeStamp
      })
      let res=toSort.map((item)=>{
        return state.records[item.index]
      })

      return res
    }
  },
  mutations:{
    SYNC_RECORDS(state,arr){
      state.records=arr
    }
  },
  actions:{
    add({dispatch},info){

      let name=info.name || info.title

      db.iterate((val,key)=>{
        if (val.name===name){
          db.removeItem(key)
        }
      }).then(()=>{
        
        db.setItem(info.id.toString(),{
          ...info,
          timeStamp:new Date().getTime()
        }).then(()=>{
          dispatch("sync")
        })

      })


    },
    remove({dispatch},info){

      db.removeItem((info.id || info).toString()).then(()=>{
        dispatch("sync")
      })
    },
    sync({commit}){

      let arr=[]
      db.iterate((val)=>{
        arr.push(val)
      }).then(()=>{
        commit('SYNC_RECORDS',arr)
      })
    },
    clear({dispatch}){
      db.clear().then(()=>{
        dispatch("sync")
      })
    }
  }

}