<template>
<div class="header-wrapper" :class="{'bottom-border':bottomBorder}">
  <div class="left-box">
    <div class="ico-wrapper" @click="$router.back()">
      <img src="/icons/arrow-left-ico@2x.png" alt="" class="ico i1">
    </div>
    <div class="vertical-line"></div>
    <router-link to="/index" class="ico-wrapper">
      <img src="/icons/home-ico@2x.png" alt="" class="ico i2">
    </router-link>
  </div>
  <div class="title-wrapper">
    {{title}}
  </div>
  <div class="right-box">
    <slot name="right">

    </slot>
  </div>
</div>
</template>
<script>
export default {
  props:{
    /*
    * @params title 标题文字
    */
    title:String,
    /* 是否有下边框 */
    bottomBorder:Boolean
  }
}
</script>
<style scoped>
.header-wrapper{
  width:100%;
  height:3.125rem;
  box-sizing: border-box;
  background-color: #FFF;
  text-align: center;
  color:#444;
  font-size: 1.125rem;
  position: fixed;
  top:0;
  left: 0;
  text-align: center;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.header-wrapper>.title-wrapper{
  width:calc( 100% );
  height:3.125rem;
  line-height:3.125rem;
  text-align: center;
  color:#444;
  font-size: 1.125rem;
  margin:0 auto;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;

}

.header-wrapper.bottom-border{
  border-bottom:0.0625rem solid #EFEFEF;
}

.header-wrapper>.left-box{
  position: absolute;
  left: 1rem;
  top: 50%;
  margin-top:-1.15625rem;
  border: 0.0625rem solid #D4D4D4;
  width:5.6875rem;
  height:2.3125rem;
  border-radius: 1.15625rem;
  display: flex;
  align-items: center;
}

.header-wrapper>.left-box>.vertical-line{
  width:0.0625rem;
  height:1.0625rem;
  background-color: #D4D4D4;
}

.header-wrapper>.left-box>.ico-wrapper{
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-wrapper>.left-box>.ico-wrapper>.ico{
  user-select: none;
}

.header-wrapper>.left-box>.ico-wrapper>.ico.i1{
  width:0.5rem;
  height:0.875rem;
}

.header-wrapper>.left-box>.ico-wrapper>.ico.i2{
  width:1.25rem;
  height:1.25rem;
}

.header-wrapper>.right-box{
  position: absolute;
  display: flex;
  align-items: center;
  height:100%;
  right:1rem;
  top:0;
  font-size: 1rem;
  color:#444;
}

.header-wrapper>.right-box .ico{
  width:1.375rem;
  height:1.375rem;
  margin-right: 0.375rem;
}
</style>